const projects = [
  {
    id: 'student-serve',
    title: "Student Serve",
    description: "A platform where students can book appointments with service providers covered in their insurance package",
    features: "| Nextjs | Google Calender | Tyescript | Tailwind |",
    imageSrc: "https://res.cloudinary.com/diyms5sde/image/upload/v1717281156/portfolio/site%20images/student-serve_q0awyf.png",
    url: 'https://student-serve.netlify.app/',
    // github: 'https://github.com/greglacinto/student-serve'
  },
  {
    id: 'loc-market',
    title: "LocMarket",
    description: "An ecommerce platform to link customers to locally made products",
    features: "| Nextjs | React-ChartJs | Typescript | Tailwind |",
    imageSrc: "https://res.cloudinary.com/diyms5sde/image/upload/v1717280903/portfolio/site%20images/image_2024-06-01_182822595_lunpld.png",
    url: 'https://locmarket-web-git-main-anyas-projects-76cc820d.vercel.app/seller/dashboard',
    // github: ''
  },
  {
    id: 'foot-place',
    title: "Foot Place",
    description: "A e-commerce website. Built with nextjs and tailwind.",
    features: "| Server Components | Typescript | Fast Refresh | Caching |",
    imageSrc: "https://res.cloudinary.com/diyms5sde/image/upload/v1702385048/portfolio/foot-place_iyjnlv.png",
    url: 'https://moore-foot.netlify.app/',
    // github: 'https://github.com/greglacinto/foot-place'
  }
  
]

export default projects; 