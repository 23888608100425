import { VStack, Text } from "@chakra-ui/react";


const Footer = () => {

    return (
        <VStack py={4}>
            <Text>copyright @2023</Text>
        </VStack>
    )
}

export default Footer;